<template>
  <div v-html="showMsg" v-if="visible"
       class="size-28 weight-500 text-center"
       :style="msgStyle"></div>
</template>
<script>
  export default {
    props: {
      list: {
        type: Array
      }
    },
    name: "PresentationMessage",
    created() {
      this.showMessage();
      window.addEventListener('mousedown', () => {
        this.$emit('done');
      })
    },
    data() {
      return {
        visible: true,
        selectedIndex: 0,
        showMsg: '',
        itv: undefined
      }
    },
    computed: {
      msg() {
        return this.list[this.selectedIndex];
      },
      msgStyle() {
        return {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%'
        }
      }
    },
    methods: {
      showMessage() {
        let msg = this.msg.split('');
        let index = 0;
        this.itv = ()=>{
          // 1글자씩 showMsg에 채우기
          this.showMsg += msg[index];
          index++;
          if(index < msg.length) {
            // 80ms 간격으로 글자 채우기
            setTimeout(this.itv, 80);
          }
          // 메시지 길이를 넘어선 경우
          else {
            // list에서 다음 메시지 인덱스로.
            this.selectedIndex++;
            // 인덱스 범위 이내일 때
            if (this.selectedIndex < this.list.length) {
              // 다음 메시지랑 사이에 br 태그 추가
              this.showMsg += '<br>';
              setTimeout(()=>{
                // 600ms 뒤에 한글자씩 다시 채우기
                this.showMessage();
              },600);
            } else {
              // 모든 메시지 표시된 경우, 500ms 이후 visible false, done emit
              setTimeout(() => {
                this.visible = false;
                this.$emit('done');
              }, 500);
            }
          }
        };
        // 최초 첫 글자 표시는 600ms 이후
        setTimeout(this.itv,600);
      },
    }
  }
</script>
