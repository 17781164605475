<template>
  <presentation-message :list="list" @done="$emit('done')"></presentation-message>
</template>

<script>
  import PresentationMessage from "../../component/PresentationMessage";
  export default {
    name: "SuperBuilderStepEnd",
    components: {PresentationMessage},
    data() {
      return {
        list: [
          '이제 모두 완료되었습니다.',
          '내 서비스 목록에서 확인할 수 있어요!'
        ]
      }
    }
  }
</script>